const API_URL = `${process.env.GATSBY_API_URL || ''}/graphql`

type ContractTypes = {
  id?: string
  name?: string
  file_link?: string
  error?: string
}

type GetContractPropTypes = {
  version: string
  usageType: string
  type: string
  fileAlias: string
}

const getContract = async ({
  type,
  usageType,
  version,
  fileAlias,
}: GetContractPropTypes): Promise<ContractTypes> => {
  const query = `
  query FindOneLinkedUploadFile($input: CTS_DOAS_SearchInput!) {
    findOneLinkedUploadFile(ctsDoasSearchInput: $input) {
        id
        file {
            name
            file_link
            version
            file_alias
            type
            usage_type
        }
    }
}
`

  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: {
          input: {
            version,
            file_alias: fileAlias,
            usage_type: usageType,
            type,
          },
        },
      }),
    })

    const jsonResponse = await response.json()
    const {
      file: { file_link },
    } = jsonResponse.data.findOneLinkedUploadFile

    return file_link
  } catch (e) {
    return {
      error: 'No file exists',
    }
  }
}

export default getContract
